import React, { useState } from "react";
import EfawtaraLogo from "../../../assets/logos/efatara-logo-long1.png";
import EfawtaraARLogo from "../../../assets/logos/eFawtara-ar.png";
import { cn } from "../../../lib/utils";
import { useSiteConfig } from "../../../config/site-config";
import LanguageSwitcher from "./language-switcher";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { siteConfig } = useSiteConfig();

  return (
    <header className="flex flex-wrap md:justify-start md:flex-nowrap z-20 w-full relative container">
      <nav className="relative w-full flex justify-between md:justify-normal lg:flex-wrap md:grid md:grid-cols-12 basis-full items-center">
        <div className="md:col-span-5">
          <Link
            className="flex-none rounded-xl text-xl inline-block font-semibold focus:outline-none focus:opacity-80"
            to="/"
          >
            <img
              src={EfawtaraLogo}
              className="w-28 md:w-36 h-auto flex rtl:hidden"
              alt="efawtara logo"
            />
            <img
              src={EfawtaraARLogo}
              className="w-28 md:w-36 h-auto hidden rtl:flex"
              alt="efawtara logo"
            />
          </Link>
        </div>

        <div className="flex items-center space-x-2 ms-auto py-1 md:ps-6 md:order-3 md:col-span-3">
          <LanguageSwitcher />
          <a
            href="https://app.efawtara.com/company/register"
            // type="button"
            className="py-2 px-3 items-center space-x-2 text-xs lg:text-base font-medium rounded-xl border border-transparent text-white bg-secondary transition disabled:opacity-50 disabled:pointer-events-none focus:outline-none hidden md:flex text-nowrap"
          >
            {siteConfig.header.cta.title}
          </a>
          <div className="md:hidden">
            <button
              onClick={() => setOpen(!open)}
              type="button"
              className="hs-collapse-toggle size-[38px] flex justify-center items-center text-sm font-semibold rounded-xl border border-gray-200 text-black hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
              data-hs-collapse="#navbar-collapse-with-animation"
              aria-controls="navbar-collapse-with-animation"
              aria-label="Toggle navigation"
            >
              {open ? (
                <svg
                  className="flex-shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              ) : (
                <svg
                  className="flex-shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="3" x2="21" y1="6" y2="6" />
                  <line x1="3" x2="21" y1="12" y2="12" />
                  <line x1="3" x2="21" y1="18" y2="18" />
                </svg>
              )}
            </button>
          </div>
        </div>

        <div
          className={cn(
            "hs-collapse transition-all duration-1000 ease-in-out overflow-hidden basis-full hidden md:block md:w-auto md:basis-auto md:order-2 md:col-span-4 w-fit",
            open
              ? "max-h-screen opacity-100"
              : "max-h-0 opacity-0 md:max-h-screen md:opacity-100"
          )}
        >
          <div className="flex flex-col gap-y-4 gap-x-0 mt-5 md:flex-row md:justify-start md:items-center md:gap-y-0 md:gap-x-3 md:mt-0 px-4">
            {siteConfig.header.nav.map((e, i) => (
              <div key={i}>
                <a
                  // scroll={true}
                  className="inline-block text-secondary uppercase text-xs lg:text-lg font-medium text-nowrap"
                  href={e.href}
                >
                  {e.title}
                </a>
              </div>
            ))}
          </div>
        </div>
      </nav>

      <div
        id="navbar-collapse-with-animation"
        className={cn(
          "hs-collapse transition-all duration-1000 ease-in-out overflow-hidden basis-full md:hidden md:w-auto md:basis-auto md:order-2 md:col-span-4 w-fit",
          open
            ? "max-h-screen opacity-100"
            : "max-h-0 opacity-0 md:max-h-screen md:opacity-100"
        )}
      >
        <div className="flex flex-col gap-y-4 gap-x-0 mt-5 md:flex-row md:justify-start md:items-center md:gap-y-0 md:gap-x-3 md:mt-0 px-4">
          {siteConfig.header.nav.map((e, i) => (
            <div key={i}>
              <a
                // scroll={true}
                className="inline-block text-secondary uppercase text-lg font-medium text-nowrap"
                href={e.href}
              >
                {e.title}
              </a>
            </div>
          ))}
        </div>
      </div>
    </header>
  );
};

export default Navbar;
