import { useTranslation } from "react-i18next";
import HeetchLogo from "../assets/images/partners/heetch.png";
import IfidasLogo from "../assets/images/partners/ifidas-logo.png";
import GlobeIcon from "../assets/icons/earth-globe.png";
import AlgeriaIcon from "../assets/icons/algeria.png";
import KSAIcon from "../assets/icons/saudi-arabia.png";
import EgyptIcon from "../assets/icons/egypt.png";

const useSiteConfig = () => {
  const { t } = useTranslation();
  const siteConfig = {
    header: {
      title: t("header.title"),
      nav: [
        // { title: "Acceuil", href: "/" },
        { title: t("header.nav.about"), href: "/#about" },
        { title: t("header.nav.offers"), href: "/#offers" },
        // { title: t("header.nav.directories"), href: "/directories" },
        { title: t("header.nav.contacts"), href: "/#contact" },
      ],
      cta: {
        title: t("header.cta.title"),
        href: "",
      },
    },
    hero: {
      title: t("hero.title"),
      description: t("hero.description"),
      cta1: {
        title: t("hero.cta1.title"),
        href: "",
      },
      cta2: {
        title: t("hero.cta2.title"),
        href: "",
      },
    },
    spontaneousPayment: {
      title: t("spontaneous_payment.title"),
      description: t("spontaneous_payment.description"),
    },
    features: {
      slogan: t("features.slogan"),
      title: t("features.title"),
      description1: t("features.description1"),
      description2: t("features.description2"),
      description3: t("features.description3"),

      features: [
        {
          title: t("features.features.payment_link_generation.title"),
          description: t(
            "features.features.payment_link_generation.description"
          ),
        },
        {
          title: t("features.features.real_time_payment_tracking.title"),
          description: t(
            "features.features.real_time_payment_tracking.description"
          ),
        },
        {
          title: t("features.features.automated_payment_reminders.title"),
          description: t(
            "features.features.automated_payment_reminders.description"
          ),
        },
        {
          title: t("features.features.detailed_reports.title"),
          description: t("features.features.detailed_reports.description"),
        },
        {
          title: t("features.features.dedicated_customer_support.title"),
          description: t(
            "features.features.dedicated_customer_support.description"
          ),
        },
      ],
    },
    subscriptions: {
      title: t("subscriptions.title"),
      description: t("subscriptions.description"),
      types: [
        {
          name: "hybrid",
          title: t("subscriptions.types.hybrid.title"),
          items: [
            {
              title: t("subscriptions.types.hybrid.items.basic.title"),
              price: "4 990",
              promo_price: "2 900",
              showPromo: true,
              currency: t("subscriptions.types.hybrid.items.basic.currency"),
              billingCycle: t(
                "subscriptions.types.hybrid.items.basic.billingCycle"
              ),
              props: [
                t("subscriptions.types.hybrid.items.basic.props.prop_1"),
                t("subscriptions.types.hybrid.items.basic.props.prop_2"),
                t("subscriptions.types.hybrid.items.basic.props.prop_3"),
                t("subscriptions.types.hybrid.items.basic.props.prop_4"),
                t("subscriptions.types.hybrid.items.basic.props.prop_5"),
                t("subscriptions.types.hybrid.items.basic.props.prop_6"),
              ],
            },

            {
              title: t("subscriptions.types.hybrid.items.pro.title"),
              price: "9 990",
              promo_price: "4 900",
              showPromo: true,
              currency: t("subscriptions.types.hybrid.items.pro.currency"),
              billingCycle: t(
                "subscriptions.types.hybrid.items.pro.billingCycle"
              ),
              props: [
                t("subscriptions.types.hybrid.items.pro.props.prop_1"),
                t("subscriptions.types.hybrid.items.pro.props.prop_2"),
                t("subscriptions.types.hybrid.items.pro.props.prop_3"),
                t("subscriptions.types.hybrid.items.pro.props.prop_4"),
                t("subscriptions.types.hybrid.items.pro.props.prop_5"),
                t("subscriptions.types.hybrid.items.pro.props.prop_6"),
              ],
            },

            {
              title: t("subscriptions.types.hybrid.items.business.title"),
              price: "14 990",
              promo_price: "7 900",
              showPromo: true,
              currency: t("subscriptions.types.hybrid.items.business.currency"),
              billingCycle: t(
                "subscriptions.types.hybrid.items.business.billingCycle"
              ),
              props: [
                t("subscriptions.types.hybrid.items.business.props.prop_1"),
                t("subscriptions.types.hybrid.items.business.props.prop_2"),
                t("subscriptions.types.hybrid.items.business.props.prop_3"),
                t("subscriptions.types.hybrid.items.business.props.prop_4"),
                t("subscriptions.types.hybrid.items.business.props.prop_5"),
              ],
            },
          ],
        },
        {
          name: "local",
          title: t("subscriptions.types.local.title"),
          items: [
            {
              title: t("subscriptions.types.local.items.basic.title"),
              price: "99 990",
              promo_price: "49 000",
              showPromo: true,
              currency: t("subscriptions.types.local.items.basic.currency"),
              billingCycle: null,
              props: [
                t("subscriptions.types.local.items.basic.props.prop_1"),
                t("subscriptions.types.local.items.basic.props.prop_2"),
                t("subscriptions.types.local.items.basic.props.prop_3"),
                t("subscriptions.types.local.items.basic.props.prop_4"),
                t("subscriptions.types.local.items.basic.props.prop_5"),
              ],
              hint: t("subscriptions.types.local.hint"),
            },
          ],
        },
      ],
    },
    testimonials: {
      title: t("testimonials.title"),
      description1: t("testimonials.description1"),
      description2: t("testimonials.description2"),
      list: [
        {
          title: t("testimonials.list.testimonial_2.title"),
          author: t("testimonials.list.testimonial_2.author"),
          position: t("testimonials.list.testimonial_2.position"),
          logo: IfidasLogo,
        },
        {
          title: t("testimonials.list.testimonial_1.title"),
          author: t("testimonials.list.testimonial_1.author"),
          position: t("testimonials.list.testimonial_1.position"),
          logo: HeetchLogo,
        },
      ],
    },
    faq: {
      title: t("faq.title"),
      items: [
        {
          question: t("faq.items.question_1.question"),
          response: t("faq.items.question_1.response"),
        },
        {
          question: t("faq.items.question_2.question"),
          response: t("faq.items.question_2.response"),
        },
        {
          question: t("faq.items.question_3.question"),
          response: t("faq.items.question_3.response"),
        },
      ],
    },
    contact: {
      title: t("contact.title"),
      description: t("contact.description"),
      form: {
        name: t("contact.form.name"),
        phone: t("contact.form.phone"),
        email: t("contact.form.email"),
        subject: t("contact.form.subject"),
        message: t("contact.form.message"),
      },
      // info: [
      //   {
      //     label: "téléphone",
      //     values: ["0770774999"],
      //   },
      //   {
      //     label: "Email",
      //     values: ["contact@efawtara.com"],
      //   },
      //   {
      //     label: "ADRESSE",
      //     values: ["Mohamadia Mall , Alger"],
      //   },
      // ],
    },
    footer: {
      links: [
        {
          title: t("footer.links.section_1.start_now.title"),
          href: "https://app.efawtara.com/company/login",
        },
        {
          title: t("footer.links.section_2.offers.title"),
          href: "/#offers",
        },
        {
          title: t("footer.links.section_3.terms_conditions.title"),
          href: "/conditiongeneraldutilisation",
        },
      ],
      title: t("features.slogan"),
      description: t("features.description1"),
      cta: t("hero.cta1.title"),
      copytights: t("footer.copytights"),

      countries: [
        {
          label: t("regions.international"),
          image: GlobeIcon,
        },
        {
          label: t("regions.KSA"),
          image: KSAIcon,
        },
        {
          label: t("regions.Egypt"),
          image: EgyptIcon,
        },
      ],
    },
  };

  return {
    siteConfig,
  };
};

export { useSiteConfig };
